@import './variables.less';


.MuiList-root {
    .MuiListItemButton-root {
        @media @smartphone {
            display: block;
        }
    }
    .MuiCollapse-wrapper {
        .MuiListItem-root {
            @media @smartphone {
                display: block;
            }
        }
    }
}
@primary-color: #038fde;