@import './variables.less';

.map-container {
    height: calc(100vh - @app-bar-height);
    width: 100%;
    .leaflet-control-container {
        div:has(.leaflet-control-zoom) {
            top: 50%;
            right: 10px;
            left: unset;
        }
    }
    .progress-bar-container {
        .progress-bar {
            z-index: calc(@max-z-index + 1);
        }
    }
}
@primary-color: #038fde;