.custom-legend {
    background-color: white; /* Set background to white */
    padding: 8px; /* Add padding around the content */
    border: 1px solid #ccc; /* Add a border around the legend */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better visibility */
    font-size: 14px;
  }
  
  .custom-legend h4 {
    margin: 0 0 5px;
    font-size: 16px;
  }
  
  .custom-legend i {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    vertical-align: middle;
  }