@import './variables.less';

.app-bar {
    height: @app-bar-height;
    @media @smartphone {
        height: 100px;
    }
    display: flex;
    justify-content: space-between; /* Pushes content to the edges */
    .MuiToolbar-regular {
        @media @smartphone {
            flex-wrap: wrap;
            justify-content: flex-end !important;
        }
    }

    padding: 5px;
    
    .button-group-container {
        position: absolute;
        top: 100%;
        margin-top: 8px;
        margin-left: 8px;
    }
}

.logo-position {
    display: flex;
    align-items: left;
}

.AboutUs-position {
    display: flex;
    align-items: right;
    margin-right: 10px;
}

.react-joyride__tooltip {
    button[data-action="primary"] {
        background-color: @navbar-color !important;
    }
    button[data-action="back"] {
        color: @navbar-color !important;
    }
}
@primary-color: #038fde;